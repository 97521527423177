<template>
  <div id="companiesContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
      <li><router-link to="/donacije/podjetja">Donatorska podjetja</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Kampanje donatorskega podjetja <span v-if="companyData">{{companyData.company_name}} ({{companyData.company_display_name}})</span></li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novo kampanijo" @click.stop="addCampaign()"></vs-button>


    <div v-if="companyData && campaignsData">


      <vs-table :data="campaignsData" @selected="showCompaniesDetails">
        <template slot="header">
          <vs-navbar class="nabarx">
            <div slot="title">
              <vs-navbar-title class="py-4">
                Kampanje donatorskega podjetja "{{companyData.company_name}} ({{companyData.company_display_name}})"
              </vs-navbar-title>
            </div>
          </vs-navbar>
        </template>
        <template slot="thead">
          <vs-th>
            Tip kampanje
          </vs-th>
          <vs-th>
            Opis
          </vs-th>
          <vs-th>
            Akcije
          </vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">


            <vs-td :data="data[index].campaign_type">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].campaignType.name}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].description">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].description}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].id">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

                <vs-button type="filled" color="primary" icon="swap_horizontal_circle" size="small"
                           class="vs-lg-12 mt-2" @click.stop="editCampaign(data[index])">Urejanje kampanje
                </vs-button>

              </vs-col>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Company v-if="companyId && sidebarActive" :companyId="companyId" />
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Company from "../../components/Sopotniki/donations/Company";


  export default {
    name: 'company-campaign',

    components: {
      Company
    },

    data() {
      return {
        companyId: this.$route.params.companyId,
        companyData: null,
        sidebarActive: false,
        campaignsData: null

      }
    },
    computed: {},

    mounted() {
      const _this = this;
      _this.getCompany().then(()=> {
        _this.getCampaigns();
      });
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.companyId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      async getCompany() {
        const _this = this;

        _this.$vs.loading();


        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/' + _this.companyId)
          .then((res) => {

            _this.companyData = res.data.data;
            console.log("companyData: ", res.data.data);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      async getCampaigns() {
        const _this = this;

        _this.$vs.loading();


        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'campaigns?donor_id=' + _this.companyId)
          .then((res) => {

            _this.campaignsData = res.data.data;
            console.log("campaignsData: ", res.data.data);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showCompaniesDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.companyId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      addCampaign() {
        const _this = this;

        _this.$router.push({name: 'Kampanija', params: {companyId: _this.companyId}});
      },

      editCampaign(campaign) {
        const _this = this;
        console.log("campaign", campaign.id);

        _this.$router.push({name: 'Kampanija', params: {companyId: _this.companyId, campaignId: campaign.id}});
      }

    }
  }
</script>

